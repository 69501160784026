import { useInView } from "react-intersection-observer";
import { Tag } from "@app/types/Cue";
import {
  AccessFlag,
  AdvertisementTypeEnum,
  Name,
  Type,
} from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import MetaTags from "@components/MetaTags/MetaTags";
import useGenerateGiftURL from "@hooks/useGenerateGiftURL";
import { useGetScrollValue } from "@hooks/useGetScrollValue";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import { useHookUpdateUrlArticle } from "@hooks/useHookUpdateUrlArticle";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import useShowGiftNofitifcationUpdate from "@hooks/useShowGiftNofitifcationUpdate";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import ArticleAttachments from "@pages/Article/components/ArticleAttachments";
import ArticleAuthorsList from "@pages/Article/components/ArticleAuthorsList";
import ArticleKeywords, {
  KeywordDisplayOption,
} from "@pages/Article/components/ArticleKeywords";
import ArticleKicker from "@pages/Article/components/ArticleKicker";
import ArticleMainImage from "@pages/Article/components/ArticleMainImage/ArticleMainImage";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";
import ArticlePurchaseLink from "@pages/Article/components/ArticlePurchaseLink";
import ArticlesFromSameSection from "@pages/Article/components/ArticlesFromSameSection/ArticlesFromSameSection";
import ArticleSocialSharing from "@pages/Article/components/ArticleSocialSharing";
import ArticleStockSidebar from "@pages/Article/components/ArticleStockSidebar/ArticleStockSidebar";
import ArticleSubscriberLabel from "@pages/Article/components/ArticleSubscriberLabel";
import ArticleSubshare from "@pages/Article/components/ArticleSubshare/ArticleSubshare";
import ArticleSubshareGiftingUpdate from "@pages/Article/components/ArticleSubshare/ArticleSubshareGiftingUpdate";
import ArticleSubshareSent from "@pages/Article/components/ArticleSubshare/ArticleSubshareSent";
import OutBrainWidget from "@pages/Article/components/OutBrainWidget";
import RelatedArticles from "@pages/Article/components/RelatedArticles/RelatedArticles";
import TelegramBanner from "@pages/Article/components/TelegramBanner";
import { ArticleProps } from "@pages/Article/types/Article";
import { checkDisplayPaywall } from "@pages/Article/utils/helpers";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import useOKTAUserStore from "@store/useOKTAUserStore";
import {
  checkIsHubArticle,
  gaEventTracker,
  getArticleJSONLD,
  getFieldValue,
  getFirstParagraph,
  getKickerFromArticleDataObject,
  getStockFromArticleDataObject,
  GoogleAdsSlotFactory,
} from "@util/helpers";
import { find } from "lodash-es";

export default function ArticleDisplayCompact({
  article,
  isGiftReceived,
  seeAlso,
  sectionNews,
  variant,
  index,
  isNavigate,
  setArticleObj,
  setArticleScrollWidth,
  isOutbrainEnabled = true,
  isPreview = false,
}: ArticleProps): React.ReactElement {
  // De-structure of article so it is cleaner to get the fields.
  const {
    id,
    title,
    updated: publishedDate,
    edited: updatedDate,
    media,
    authors,
    sections,
    tags,
    sanitizedRelatedStories,
    paywall,
    displaySetting,
    embedCodes,
    elements,
    relatedContributorProfile,
    shortUrl,
    urlPath,
    storyline,
    attachments,
  } = article;

  const mainSection = sections?.at(0);
  const articleCategory =
    mainSection?.parent.name !== "Home"
      ? mainSection?.parent.name
      : mainSection.name;
  const kicker = getKickerFromArticleDataObject(article);
  const contentAccess = paywall?.contentAccess || "0";
  const displayPaywall = checkDisplayPaywall({
    isGiftReceived,
    variant: variant,
    contentAccess: contentAccess,
  });
  const showRelatedLinksAndNewsletterSignupForm =
    displaySetting?.showRelatedLinksAndNewsletterSignupForm || false;

  const standFirstField =
    find(elements, { type: Type.Standfirst })?.fields || [];
  const standFirst = getFieldValue(standFirstField, Name.Standfirst);
  const stockCodes = getStockFromArticleDataObject(article);
  const isPremium = contentAccess === "1";

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const mostPopularData = useMostReadArticleList();
  const breakingNews = useTrimmedBreakingNews();

  const {
    alacrityRes,
    isModalOpen,
    setIsModalOpen,
    handleGenerateGiftURLModal,
  } = useGenerateGiftURL(isPremium);
  const { showGiftNotification, setShowGiftNotification } =
    useShowGiftNofitifcationUpdate(isPremium);
  const { gsChannels, gsChannelsNames } = useGrapeshotData(urlPath);
  const firstParagraph = getFirstParagraph(article.elements || []);
  const { ref } = useHookUpdateUrlArticle(urlPath, isNavigate);

  const { prevScrollPos } = useGetScrollValue();
  const { ref: articleRef } = useInView({
    threshold: [0.1],
    onChange: (inView) => {
      if (inView) {
        setArticleObj(article);
      }
    },
  });

  const { ref: endOfArticle } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        gaEventTracker("end of article", "visible", article.urlPath);
      }
    },
  });

  const isBrandedContent = checkIsHubArticle(article.elements || []);
  return (
    <>
      <MetaTags
        title={article?.seoFields?.metaTitle || title}
        articleId={article.id}
        description={article?.seoFields?.metaDescription || firstParagraph}
        keywords={article?.tags?.map((tag) => tag.name)}
        slug={article.urlPath}
        shortLink={"/article/" + article.id}
        canonicalUrl={article?.seoFields?.canonicalUrl}
        imageUrl={article?.media?.[0]?.content?.fields?.["original-caas"]?.url}
        imageAlt={
          article?.media?.[0]?.summary?.find(
            (summaryItem) => summaryItem.key === "alttext"
          )?.value
        }
        jsonLd={getArticleJSONLD(article)}
        robots={"index, follow, max-image-preview:large"}
        gsChannels={gsChannels}
        articleCategory={articleCategory}
        accessFlag={isPremium ? AccessFlag.Premium : AccessFlag.Free}
        publishedDate={publishedDate}
        updatedDate={updatedDate}
      />

      <ArticleSubshareGiftingUpdate
        isModalOpen={showGiftNotification}
        setIsModalOpen={setShowGiftNotification}
        {...{
          handleGenerateGiftURLModal,
        }}
      />

      <ArticleSubshareSent {...{ alacrityRes, isModalOpen, setIsModalOpen }} />

      <article
        className="display-type--compact w-full py-5"
        data-index={index}
        data-cueid={id}
        ref={ref}
      >
        <div ref={articleRef}>
          <div
            ref={(e) => {
              if (e?.offsetHeight) {
                const height = e.offsetHeight;
                const scrollPercent = (prevScrollPos - e?.offsetTop) / height;
                const scrollPercentRounded = Math.round(scrollPercent * 100);
                setArticleScrollWidth(id, scrollPercentRounded);
              }
            }}
          >
            <Container rootClassName="pt-4">
              <Row>
                <Column rootClassName="w-full">
                  <div className="flex flex-wrap items-center space-x-1">
                    <ArticleKicker kicker={kicker} />

                    {isPremium && kicker ? (
                      <div className="font-poppins text-4xs tracking-[1px] text-gray-650">
                        ·
                      </div>
                    ) : null}

                    <ArticleSubscriberLabel isPremium={isPremium} />
                  </div>

                  <h1
                    data-testid="article-title"
                    className="font-lct text-8xl font-bold leading-normal text-black md:text-12xl"
                  >
                    {title}
                  </h1>

                  <>
                    {typeof standFirst === "string" ? (
                      <p className="mt-2 font-poppins">{standFirst}</p>
                    ) : null}
                  </>
                </Column>

                <Column rootClassName="w-full lg:w-8/12">
                  <>
                    {publishedDate && updatedDate ? (
                      <ArticleAuthorsList
                        rootClassName="mb-6 mt-2"
                        authors={authors}
                        contributors={relatedContributorProfile}
                        updatedDate={updatedDate}
                        publishedDate={publishedDate}
                        hasUpdatedTimeDisplayed={
                          displaySetting?.hasUpdatedTimeDisplayed
                        }
                      />
                    ) : null}
                  </>

                  <div className="mb-6 flex items-center justify-between border-b border-t border-gray-250 py-4">
                    {tags ? (
                      <ArticleKeywords
                        keywords={tags}
                        keywordDisplayOption={KeywordDisplayOption.First}
                      />
                    ) : null}

                    <div className="flex space-x-3">
                      <ArticleSocialSharing
                        articleTitle={title}
                        shareUrl={shortUrl}
                      />

                      <ArticleSubshare
                        isPremium={isPremium}
                        userType={OKTAUserInfo?.usertype}
                        {...{
                          handleGenerateGiftURLModal,
                          setIsModalOpen,
                          alacrityRes,
                        }}
                      />
                    </div>
                  </div>

                  <ArticleAttachments
                    attachments={attachments}
                    rootClassName="md:mx-8"
                  />

                  <>
                    {media ? (
                      <ArticleMainImage
                        media={media.slice(0, 1)}
                        rootClassName="float-left mr-6 mt-2 w-[350px] max-w-[50%]"
                        captionRootClassname="p-2 font-poppins text-xs text-gray-550"
                        thumbnailRootClassName="bg-gray-250 aspect-3x2 flex items-center justify-center"
                        imgRootClassName="h-full m-auto"
                        invisibleImgRootClass="h-full"
                        landscapeImgRootClassName="h-auto"
                      />
                    ) : null}
                  </>

                  <>
                    {elements && storyline ? (
                      <ArticleParagraphs
                        className="body-content mb-6 mt-4 text-lg font-light text-gray-850"
                        {...{
                          id,
                          elements,
                          seeAlso,
                          embedCodes,
                          displayPaywall,
                          sections,
                          index,
                          gsChannelsNames,
                          isPremium,
                          storyline,
                          keywords: tags,
                          displayRelatedLinksAndNewsletterSignupForm:
                            showRelatedLinksAndNewsletterSignupForm,
                          isGifted: isGiftReceived,
                          isBrandedContent,
                          isPreview,
                        }}
                      />
                    ) : null}
                  </>

                  <div ref={endOfArticle}></div>

                  <>
                    {tags ? (
                      <ArticleKeywords
                        keywords={tags}
                        keywordDisplayOption={KeywordDisplayOption.Rest}
                      />
                    ) : null}
                  </>

                  <RelatedArticles articles={sanitizedRelatedStories} />

                  <TelegramBanner />

                  <>
                    {!isBrandedContent ? (
                      <ArticlesFromSameSection
                        data={sectionNews}
                        sections={sections}
                      />
                    ) : null}
                  </>
                </Column>

                <Column rootClassName="w-full lg:w-4/12">
                  <>
                    {mainSection ? (
                      <div className="lg:h-[1050px]">
                        <Advertisement
                          rootClassName="lg:sticky lg:top-[120px] mb-5"
                          adUnitProps={{
                            id:
                              index !== 0
                                ? `dfp-ad-imu2-${index}`
                                : `dfp-ad-imu2`,
                            type: AdvertisementTypeEnum.IMU2,
                            slot: GoogleAdsSlotFactory.imu2(
                              mainSection.uniqueName
                            ),
                            isRefresh: index !== 0,
                            slotTargettings: [
                              { key: "btarticleid", value: article.id },
                              {
                                key: "bttags",
                                value:
                                  article.tags
                                    ?.map((tag: Tag) =>
                                      getFormattedTextForAds(tag.name)
                                    )
                                    .join(", ") || "",
                              },
                              {
                                key: "gs_channels",
                                value: gsChannelsNames.join(","),
                              },
                            ],
                          }}
                        />
                      </div>
                    ) : null}
                  </>

                  <ArticleStockSidebar codes={stockCodes} />

                  <BreakingNews
                    rootClassName="mx-auto max-w-[300px]"
                    data={breakingNews}
                  />

                  <>
                    {mostPopularData ? (
                      <div className="mx-auto mb-6 max-w-[300px]">
                        <MostPopular data={mostPopularData} />
                      </div>
                    ) : null}
                  </>

                  <>
                    {paywall?.copyright === "SPH" && publishedDate ? (
                      <div className="mx-auto mb-6 max-w-[300px]">
                        <ArticlePurchaseLink
                          title={title}
                          publishedDate={publishedDate}
                        />
                      </div>
                    ) : null}
                  </>

                  <>
                    {mainSection ? (
                      <Advertisement
                        rootClassName="lg:sticky lg:top-[120px]"
                        adUnitProps={{
                          id:
                            index !== 0
                              ? `dfp-ad-imu3-${index}`
                              : `dfp-ad-imu3`,
                          type: AdvertisementTypeEnum.IMU3,
                          slot: GoogleAdsSlotFactory.imu3(
                            mainSection.uniqueName
                          ),
                          isRefresh: index !== 0,
                          slotTargettings: [
                            { key: "btarticleid", value: article.id },
                            {
                              key: "bttags",
                              value:
                                article.tags
                                  ?.map((tag: Tag) =>
                                    getFormattedTextForAds(tag.name)
                                  )
                                  .join(", ") || "",
                            },
                            {
                              key: "gs_channels",
                              value: gsChannelsNames.join(","),
                            },
                          ],
                        }}
                      />
                    ) : null}
                  </>
                </Column>

                <>
                  {article.urlPath && isOutbrainEnabled ? (
                    <OutBrainWidget
                      articleUrl={article.urlPath}
                      widgetId={index === 0 ? "AR_4" : "AR_5"}
                    />
                  ) : null}
                </>
              </Row>
            </Container>
          </div>
        </div>
      </article>
    </>
  );
}
